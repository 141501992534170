export const playList = [
    {
        id: 1,
        name: "Drift To Peace",
        description: "Draining out accumulated stress and anxiety",
        writer: "Liminal Coaching",
        img: "https://cdn.pixabay.com/photo/2021/11/04/05/33/dome-6767422_960_720.jpg",
        src: process.env.REACT_APP_RESOURCES_WORKER_URL + "/assets/audio/drift-to-peace.mp3",
    },
];

export function getTrack(id: number) {
    return [playList[id]];
}