import React from "react";
import {useSetRecoilState} from "recoil";
import {ShowPlayerState} from "../store/ShowPlayerStore";
interface RenderContentGateProps {
    show: boolean;
}

export const RenderContentGate = ( { show }:RenderContentGateProps) => {
    const setShowPlayer = useSetRecoilState(ShowPlayerState);
    if(show) return null;
    return (
        <div className="w-full cursor-pointer bg-blue-200 p-8 text-center hover:underline" onClick={() => {setShowPlayer(true)}}>
            Download Guided Relaxation
        </div>
    );
}