import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from './serviceWorkerRegistration';
import {
    RecoilRoot
} from 'recoil';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
    <RecoilRoot>
        <App />
    </RecoilRoot>
);

serviceWorker.register();