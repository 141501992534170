import React from "react";
import AudioPlayer from "react-modern-audio-player";

interface RenderAudioPlayerProps {
    playlist: any;
    show: boolean;
}

export const RenderAudioPlayer = ( { show, playlist }:RenderAudioPlayerProps) => {
    if(!show) return null;
    return (
        <AudioPlayer
            playList={playlist}
            activeUI={{
                all: true,
                artwork: false,
                playButton: true,
                trackInfo: false,
                trackTime: false,
                repeatType: false,
                playList: false,
                volume: false,
                progress: "bar"
            }}
            placement={{
                interface: {
                    templateArea: { //@ts-ignore
                        progress: "row2-1", //@ts-ignore
                        playButton: "row1-2", //@ts-ignore
                        playList: "row1-1",
                    },
                },
                player: "static",
            }}
        />
    );
}