import React from 'react';
import './public.css';
import { Head } from "./components/Head";
import { MainContent } from "./components/MainContent";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";

const isOnline: boolean = navigator.onLine;


function App() {
    return (
        <div>
            <Head title={"Liminal Space"} description={"Liminal Space"} />
            <Header />
            <MainContent isOnline={isOnline} />
            <Footer />
        </div>
    );
}

export default App;
